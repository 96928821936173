* {
  user-select: none;
}
*::selection {
  background: none;
}
*::-moz-selection {
  background: none;
}


h1 {
  font-size: calc(3em + 1vw);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.2;
}

h2 {
  font-size: calc(2em + 1vw);
  text-transform: capitalize;
  font-weight: bold;
  color: var(--main-color);
  line-height: 1.2;

}

h3 {
  font-size: calc(1.8em + 1vw);
  text-transform: capitalize;
  font-weight: 500;
}

h4 {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 500;
}

h5 {
  font-size: 25px;
  text-transform: capitalize;
  font-weight: 500;
}

p {
  line-height: 35px;
}

.gallery-content {
  columns: 5 320px;
}

.gallery-item {
  columns: 5 320px;
}

.gallery-content img {
  margin: 10px;
}

.pswp__img {
  object-fit: cover !important;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: calc(2em + 1vw);
  }

  h4 {
    font-size: calc(1em + 1vw);
  }

}

.content-directorios {
  align-self: center;
}

.bgblockAboutbgImage{
  position: relative;
}
.bgblockAboutbgImage::before{
  content: '';
  position: absolute;
  top: 50px;
  background-image: url('../css/casaplano.png');
  background-size: 50%;
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

/*estilo del contador de info */
.bgCountent{
  position: relative;
  padding: 150px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bgCountent::before{
  content: '';
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}

/*bg de contenedor de video*/
.bgCountentVideo {
  position: relative;
  padding: 100px 0 0;
  margin-bottom: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
}

.bgCountentVideo .info {
  width: 60%;
  margin: 0 auto -80px;
}

.bgCountentVideo .countentVideoInfo {
  position: relative;
  text-align: center;
  color: white;
}

.bgCountent::before,
.bgFormHome::before,
.bgCountentVideo::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.bgCountentVideo::before {
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
  background-color: var(--overlay-video-content);
}

.bgCountent::before,
.bgFormHome::before {
  background-color: rgba(0, 0, 0, 0.6);
}



@media screen and (max-width:569px) {
  .bgCountentVideo {
    margin-bottom: 100px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bgCountentVideo::before{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bgCountentVideo .info {
    width: 90%;
    margin: 0 auto -30px;
  }
}